export type OrderState = "new" | "accepted" | "verification" | "manufacturing" | "waiting_for_release" | "ready_for_pickup" | "ready_for_shipping" | "completed" | "cancelled" | "rejected" | "reservation_exceeded"
export const orderStates: OrderState[] = [
    "new",
    "accepted",
    "verification",
    "manufacturing",
    "waiting_for_release",
    "ready_for_pickup",
    "ready_for_shipping",
    "completed",
    "cancelled",
    "rejected",
    "reservation_exceeded"
]
export const userAllowedPrescriptionUploadStates: OrderState[] = [
    "new",
    "accepted",
    "verification"
]