<script setup lang="ts">
import { assetLink } from "@/common/util"
import { $prescriptionTransmission } from "@/common/util/converter"
import type { Order as BaseOrder } from "@/model/backend/order"
import { orderStates } from "@/model/order-state"
import { useShopAppConfig } from "@/shop/composables"
import { useDevMode } from "@/shop/store/useDevMode"
import { $currency, useHttpClient } from "@/vf"
import { storeToRefs } from "pinia"
import { computed, ref } from "vue"
import { useRouter } from "vue-router"
import CheckoutPrescriptionTransmissionHint from "./CheckoutPrescriptionTransmissionHint.vue"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
type Order = Omit<BaseOrder, "totalTaxes"> & { totalTaxes: Record<string, { vat: number; tax: number }> }
const props = withDefaults(
    defineProps<{
        order?: string
    }>(),
    {
        order: undefined,
    },
)

/*─────────────────────────────────────┐
│   functions                          │
└─────────────────────────────────────*/
async function activate(): Promise<void> {
    if (!props.order) {
        router.push({ name: "home" })
        return
    }

    try {
        order.value = (await http.get<Order>("my-account/order/" + props.order)).data
        for (const s of appConfig.value.shippingMethods) {
            if (s.name == order.value.shippingProvider) {
                shippingName.value = s.title
                break
            }
        }
        for (const s of appConfig.value.payment) {
            if (s.name == order.value.paymentProvider) {
                paymentName.value = s.title
                break
            }
        }
    } catch (e) {
        router.push({ name: "home" })
    }
}

/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const { appConfig } = useShopAppConfig()
const http = useHttpClient()
// const cartStore = useCartStore()
const router = useRouter()
const { devMode } = storeToRefs(useDevMode())
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const order = ref<Order>(undefined!) // activate() catches this if it not defined
const shippingName = ref<string>()
const paymentName = ref<string>()
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
await activate()

const digitalTokenWasUploaded = computed(() => {
    return order.value.prescriptionTransmission !== "digital/token" && order.value.uploads?.length
})
</script>

<template>
    <template v-if="devMode">
        <button v-for="state in orderStates" :key="state" @click="order.state = state">{{ state }}</button>
    </template>
    <div class="container my-5">
        <div class="small text-muted mb-5 pb-3">Home / Warenkorb</div>

        <template v-if="order.state == 'new'">
            <h2>Bestellung erfolgreich</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>Vielen Dank für deine Bestellung, diese wird nun von uns geprüft</h1>
            </div>
        </template>

        <template v-if="order.state == 'accepted'">
            <h2>Bestellung angenommen</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>Wir haben deine Bestellung geprüft und erwarten nun dein Original-Rezept</h1>
            </div>
        </template>

        <template v-if="order.state == 'verification'">
            <h2>Auftrag in Bearbeitung</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1 v-if="!order.prescriptionChecked">Wir erwarten dein Original-Rezept</h1>
                <h1 v-else>Wir erwarten deine Zahlung</h1>
            </div>
            <CheckoutPrescriptionTransmissionHint v-if="!digitalTokenWasUploaded" v-bind="{ order }" />
        </template>

        <template v-if="order.state == 'manufacturing'">
            <h2>Auftrag in Herstellung</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>Deine Bestellung wird nun hergestellt</h1>
            </div>
        </template>

        <template v-if="order.state == 'waiting_for_release'">
            <h2>Qualitätskontrolle</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>Deine Bestellung wurde hergestellt und befindet sich in der Prüfung</h1>
            </div>
        </template>

        <template v-if="order.state == 'ready_for_pickup'">
            <h2>Bereit zur Abholung</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>Deine Bestellung ist fertig und kann an unserem Pick-Up-Schalter abgeholt werden</h1>
            </div>
        </template>

        <template v-if="order.state == 'ready_for_shipping'">
            <h2>Im Versand</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>Deine Bestellung ist fertig und wird nun verschickt</h1>
            </div>
        </template>

        <template v-if="order.state == 'completed'">
            <h2>Abgeschlossen</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>Deine Bestellung ist abgeschlossen</h1>
            </div>
        </template>

        <template v-if="order.state == 'cancelled'">
            <h2>Storniert</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>Deine Bestellung wurde storniert</h1>
            </div>
        </template>

        <template v-if="order.state == 'rejected'">
            <h2>Abgelehnt</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>Wir können deine Bestellung leider nicht bearbeiten</h1>
            </div>
        </template>

        <template v-if="order.state == 'reservation_exceeded'">
            <h2>Reservierungsdauer überschritten</h2>
            <div class="mb-3 d-flex justify-content-between">
                <h1>
                    Wir können deine Bestellung nicht länger für dich reservieren. Bitte melde dich schnellstmöglich bei
                    uns um das weitere Vorgehen zu besprechen
                </h1>
            </div>
        </template>

        <div>
            <div class="row my-5">
                <div class="col-md-4">
                    <h3>Rechnungsadresse</h3>
                    {{ order.invoiceFirstName }} {{ order.invoiceLastName }}
                    <br />
                    {{ order.invoiceStreet }}
                    <br />
                    {{ order.invoiceZip }} {{ order.invoiceCity }}
                </div>
                <div class="col-md-4 mt-4 mt-md-0">
                    <h3>Lieferadresse</h3>
                    <div v-if="order.shippingProvider != 'pickup' && order.shippingProvider !== 'pickup_service'">
                        {{ order.deliveryFirstName }} {{ order.deliveryLastName }}
                        <br />
                        {{ order.deliveryStreet }}
                        <br />
                        {{ order.deliveryZip }} {{ order.deliveryCity }}
                    </div>
                    <div v-else>Abholung in der Apotheke</div>
                </div>
                <div class="col-md-4 mt-4 mt-md-0">
                    <h3>Informationen</h3>

                    <p>
                        <b class="d-block">Bestellnummer:</b>
                        {{ order.orderNumber }}
                    </p>

                    <p>
                        <b class="d-block">Gewählte Zahlungsart:</b>
                        {{ paymentName }}
                    </p>

                    <p>
                        <b class="d-block">Versandart:</b>
                        {{ shippingName }}
                    </p>

                    <p v-if="order.prescriptionTransmission">
                        <b class="d-block">Rezept-Art:</b>
                        {{ $prescriptionTransmission(order.prescriptionTransmission) }}
                    </p>
                </div>
            </div>

            <template v-for="item in order.items" :key="item.id">
                <template v-if="!item.isSpecialType">
                    <div class="row checkout-cart-item align-items-center my-5">
                        <div class="col-md-2 d-none d-md-block">
                            <div class="pr-4">
                                <img
                                    v-if="!item.article?.imageName"
                                    src="@/shop/assets/blossom.png"
                                    class="img-fluid"
                                />
                                <img
                                    v-else
                                    class="img-fluid"
                                    :src="assetLink('/uploads/media/' + item.article.imageName)"
                                />
                            </div>
                        </div>
                        <div class="col-10 col-md-5">
                            <div class="checkout-cart-item-brand">
                                {{ item.article?.publicNamePrimary }}
                            </div>
                            <div class="checkout-cart-item-name">{{ item.articleName }}</div>
                            <div
                                v-if="
                                    item.article &&
                                    (item.article.type === 'essence' || item.article.type === 'cannabis')
                                "
                                class="checkout-cart-item-info"
                            >
                                THC {{ item.article.thc }} % &middot; CBD {{ item.article.cbd }} %
                            </div>
                        </div>

                        <div class="col-12 col-md-5">
                            <div class="mt-3 mt-md-0 d-flex justify-content-between align-items-end">
                                <div>
                                    <div class>
                                        <b>Menge</b>
                                    </div>

                                    <span class="d-inline-block text-center" style="min-width: 40px">
                                        {{ item.amount }}
                                        {{ item.packageAmount !== 1 ? "x " + item.packageAmount : "" }}
                                        {{ item.unit }}
                                    </span>
                                </div>
                                <div class="text-right">
                                    <div class="article-price">
                                        {{ $currency(item.totalPrice) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="my-2" />
                </template>
            </template>

            <table class="table table-borderless my-3">
                <template v-for="item in order.items" :key="item.id">
                    <tr v-if="item.isSpecialType">
                        <td colspan="4" class="text-right">
                            {{ item.articleName }}
                        </td>
                        <td class="text-right">
                            {{ $currency(item.totalPrice) }}
                        </td>
                    </tr>
                </template>
                <template v-if="order.totalTax > 0">
                    <tr>
                        <td colspan="4" class="text-right">Gesamtsumme ohne MwSt.</td>
                        <td class="text-right">
                            {{ $currency((order.totalPrice ?? 0) - order.totalTax) }}
                        </td>
                    </tr>
                    <tr v-for="item in order.totalTaxes" :key="item.vat">
                        <td colspan="4" class="text-right">zzgl. {{ item.vat }}% MwSt.</td>
                        <td class="text-right">
                            {{ $currency(item.tax) }}
                        </td>
                    </tr>
                </template>
                <tr>
                    <td colspan="5"></td>
                </tr>
                <tr>
                    <td colspan="4" class="text-right font-weight-bold">Gesamtsumme</td>
                    <td class="text-right font-weight-bold" style="min-width: 30%">
                        {{ $currency(order.totalPrice) }}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
